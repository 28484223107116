import React from 'react';
import markWithPatient from '../images/mark_at_work.jpg';
import equipment1 from '../images/equipment1.jpg';
import sheaWithPatient from '../images/shea_with_patient.jpg';
import cashRates from '../images/riseCashRates.jpg';

const Services = () => {
    const redirect = () => {
        window.location.href = '/contact'
    }

    return (
        <div className='servicesPage'>
            <header className='servicesHeader'>
                <div className='servicesImgDiv'>
                    <h1>Physical Therapy Services</h1>
                    <img className='serviceHeaderImg' src={markWithPatient} alt='Mark with patient'/>
                    <img className='serviceHeaderImg equipImg' src={equipment1} alt='exercise equipment'/>
                    <img className='serviceHeaderImg' src={sheaWithPatient} alt='Shea with patient'/>
                </div>
                <div className='servicesHeaderText'>
                    <p className='subheading'>Rise Orthopedic & Sports Therapy offers a full range of physical therapy services for all ages and stages of life.</p>
                    <p>
                        We understand the road to recovery is a difficult process. 
                        We are committed to providing you the best care possible while helping with your rehabilitation process. 
                        At Rise, our goal is to make sure you're comfortable with your treatment plan and the specialists you're working with. 
                        Establishing a personal relationship allows us to encourage you accordingly while motivating you past the disappointment and fear 
                        that physical therapy can bring.
                    </p>
                    <p className='listHeading'>We provide the following inpatient physical therapy services.</p>
                    <ul>
                        <a href='/sports_medicine'><li>Athletic Injuries</li><i className="fas fa-external-link-alt"></i></a>
                        <li>Postoperative Rehabilitation</li>
                        <li>Corrective Therapy</li>
                        <li>Shoulders</li>
                        <li>Personal & Work Injuries</li>
                        <li>Aches & Pains</li>
                        <a href='/dry_needling'><li>Manual Physical Therapy</li><i className="fas fa-external-link-alt"></i></a>
                        <li>Therapeutic Modalities</li>
                    </ul>
                </div>
            </header>
            <section id='cashRates'>
                <img src={cashRates} alt='logo'/>
                <div className='ratesCard'>
                    <div className='PTEval'>
                        <div>
                            <h2>Physical Therapy Evaluations</h2>
                            <p>If you have an injury or you’re dealing with pain, you can always come in on your own. Some patients don’t want to wait on a referral or insurance approval and submit costs for reimbursement after paying for an evaluation.</p>
                        </div>
                        <h1>$150</h1>
                    </div>
                    <div className='followUp'>
                        <div>
                            <h2>Follow Up Appointments</h2>
                            <p>Check in with Mark and his team to analyze your progress and update us on how you’re feeling. A follow up allows us to stay on top of treatment and continue to encourage you to work towards recovery.</p>
                        </div>
                        <h1>$100</h1>
                    </div>
                    <div className='manualPT'>
                        <div>
                            <h2>Manual Physical Therapy</h2>
                            <p>Our practice offers dry needling and cupping treatment options for retail customers. Each service is billed per 15 minutes and some insurance companies allow you to submit the cost for reimbursement.</p>
                        </div>
                        <h1>$50</h1>
                    </div>
                </div>
            </section>
            <section className='getStarted'>
                <h1>How to get Started</h1>
                <div className='planDiv'>
                    <div>
                        <i className="far fa-calendar-alt"></i>
                        <h3>Schedule an Evaluation</h3>
                        <p>Determine your Diagnosis</p>
                    </div>
                    <div>
                        <i className="far fa-edit"></i>
                        <h3>Treatment Planning</h3>
                        <p>Create a plan specific for your needs</p>
                    </div>
                    <div>
                        <i className="fas fa-walking"></i>
                        <h3>Begin your Recovery</h3>
                        <p>Set goals and begin to heal</p>
                    </div>
                </div>
                <button className='requestApptBtn' onClick={redirect}>Request an Appointment</button>
            </section>
        </div>
    )
}

export default Services;