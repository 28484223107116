import React from "react";
import logo from "../images/riseLogo.png";
import rise_lobby from "../images/Rise_entry.jpg";

const Patients = () => {
  const patientForms = () => {
    window.location.href = "/new_patients";
  };

  return (
    <div className="patientsPage">
      <section className="whatToExpect">
        <div className="whatToExpectText">
          <img className="patientsLogo" src={logo} alt="logo" />
          <h2>What you can Expect as a New Patient</h2>
          <p>
            When you arrive at our Surprise location, you can expect a warm
            and friendly greeting from a staff that that genuinely cares
            about you. At Rise, our guests are treated as individuals and
            not just patients. We want you to be comfortable in the
            environment you’re rehabbing in so you can relax and focus on
            recovery. To give you the space you need, we’ve designed an
            open floor plan that includes a private evaluation room and a
            separate waiting area.
          </p>
          <p>
            Owner Mark Jagodzinski is committed to personal relationships
            and wants his practice to be a positive experience for
            patients. “We’re going to manage the whole body and not just
            symptoms in one area,” Mark says. This type of approach is what
            he’s always been able to hang his hat on. Whether it’s the
            state of the art equipment or Mark’s personality and
            experience, Rise is geared towards the well-being of your
            “whole body”.
          </p>
        </div>
        <img className="riseLobbyImg" src={rise_lobby} alt="Rise lobby" />
      </section>
      <section className="uponArrival">
        <h1>Upon arrival, you'll need...</h1>
        <div className="whatYouNeed">
          <div>
            <h3>ID and Insurance</h3>
            <p>
              We'll need to verify your indentity and solidify your method
              of payment.
            </p>
          </div>
          <div>
            <h3>Appropriate Attire</h3>
            <p>
              We recommend wearing clothing that you'll be comfortable
              working out in.
            </p>
          </div>
          <div>
            <h3>Motivated Mindset</h3>
            <p>
              We encourage you to come in ready to work towards your
              recovery!
            </p>
          </div>
        </div>
        <button
          className="requestApptBtn formsPageBtn"
          onClick={patientForms}
        >
          New Patient Intake Forms
        </button>
      </section>
    </div>
  );
};

export default Patients;
