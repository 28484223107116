import React, { useState } from 'react';
import { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

const NavHamburger = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown className='hamburgerMenu' isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className='hamburgerNav'>
                <i className="fas fa-bars"></i>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem href='/'>Home</DropdownItem>
                <DropdownItem href='/services'>Services</DropdownItem>
                <DropdownItem href='/#services'>Services Offered</DropdownItem>
                <DropdownItem href='/sports_medicine'>Sports Medicine</DropdownItem>
                <DropdownItem href='/dry_needling'>Dry Needling</DropdownItem>
                <DropdownItem href='/patients'>Patients</DropdownItem>
                <DropdownItem href='/new_patients'>New Patient Information</DropdownItem>
                <DropdownItem href='/accepted_insurance_plans'>Insurances Accepted</DropdownItem>
                <DropdownItem href='/accepted_insurance_plans#cashRates'>Cash Rates</DropdownItem>
                <DropdownItem href='/about'>About</DropdownItem>
                <DropdownItem href='/contact'>Contact</DropdownItem>
            </DropdownMenu>
        </Dropdown>
  );
}

export default NavHamburger;