const insurancePlans = [
    'Aetna',                                                                         
    'Allwell',
    'Ambetter',
    'Ameriben',  
    'BCBS',
    'BCBS of Anthem',
    'BCBS of AZ',
    'BCBS Federal',
    'BCBS Regence',
    'Care 1st',
    'Champ VA',
    'Cigna',
    'Cigna Healthsprings',
    'Government Health (GEHA)',
    'Humana Gold',
    'Humana Medicare',
    'Humana National',
    'Medica',
    'Medicare',
    'Medicare Railroad',
    'Medico',
    'Medrisk',
    'Optum Health',
    'PCHS Multiplan',
    'Samba Federal Health Plan',
    'Tricare for Life',
    'Triwest',
    'UnitedHealth Care Choice Plus',
    'UnitedHealth Care Golden Rule',
    'UnitedHealth Care Medicare',
    'UnitedHealth Care Community',
    'Unity Healthshares',
    'Veterans Affairs'
]

export default insurancePlans;