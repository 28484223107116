import React from 'react';
import ptTech from '../images/ptTech.jpg';
import rapidboot from '../images/rapidboot.jpg';
import tables from '../images/tables.jpg';

const SportsMedicine = (props) => {
    const contact = () => {
        window.location.href = '/contact'
    }
    return (
        <div className='sportsMedPage'>
            <header className='sportsMedHeader'>
                <h1>Rise Orthopedic Sports Specialists</h1>
                <p>
                Athletics are growing in Surprise, AZ and we're eager to help student athletes 
                recover stronger than ever and get back to playing the game they love. 
                Contact us below if you're looking for a physical therapist that specializes in sports medicine.
                </p>
                <div className='headerImgDiv'>
                    <img src={rapidboot} alt='rapid reboot'/>
                    <img src={ptTech} alt='physical therapy tech'/>
                    <img src={tables} alt='therapy tables'/>
                </div>
            </header>
            <div className='whyVisit'>
                <h2>Why Visit Rise for Sports Related Injuries?</h2>
                <div>
                    <p>
                    Whether you need to rehab an injury or are planning for postoperative recovery, 
                    we take pride in providing top of the line physical therapy services. Founder and 
                    physical therapist, Mark Jagodsinski, has had multiple major surgeries and understands 
                    the importance of treating the entire body. Instead of simply preventing the sports 
                    injury from reoccuring, we strengthen the surrounding muscles and joints to keep your 
                    body balanced.
                    </p>
                    <button className='requestApptBtn sportsMedBtn' onClick={contact}>Request an Appointment</button>
                </div>
            </div>
        </div>
    )
}

export default SportsMedicine;