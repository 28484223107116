import React from "react";
import ContactForm from "./contactForm";

const Contact = () => {
  return (
    <div className="contactPage">
      <ContactForm />
      <section className="businessInfo">
        <div className="addressInfo">
          <h3>Address:</h3>
          <p>12515 W Bell Rd, Suite 103</p>
          <p>Surprise, AZ 85378</p>
        </div>
        <div className="phoneFax">
          <div className="phone">
            <h3>Phone:</h3>
            <a href="tel: 623-234-4171">
              <p>623.234.4171</p>
            </a>
          </div>
          <div className="fax">
            <h3>Fax:</h3>
            <p>623.234.4173</p>
          </div>
        </div>
        <div className="busHours">
          <h3>Business Hours:</h3>
          <p>Monday - Thursday: 7 a.m. - 12 p.m., 1 p.m. - 6 p.m.</p>
          <p>Friday: 7 a.m. - 2 p.m.</p>
          <p>Saturday by Appointment Only</p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.7444192309017!2d-112.3295833!3d33.637868299999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b43590bbb3f17%3A0xb04bf00c71f1e6b7!2s12515%20W%20Bell%20Rd%20%23103%2C%20Surprise%2C%20AZ%2085378!5e0!3m2!1sen!2sus!4v1673474912344!5m2!1sen!2sus"
          width="400"
          height="300"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <iframe className='mapImg' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.754036442438!2d-112.33546668494687!3d33.637618980720696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b43587bbc78a7%3A0x11f74a4580981175!2sRise%20Orthopedic%20and%20Sports%20Physical%20Therapy!5e0!3m2!1sen!2sus!4v1604432568960!5m2!1sen!2sus" title='Rise Intro' width="350" height="250" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
      </section>
    </div>
  );
};

export default Contact;
