import React from 'react';
import insurancePlans from '../data/insurancePlan';
import cashRates from '../images/riseCashRates.jpg';

const Insurance = () => {
    const redirect = () => {
        window.location.href = '/contact'
    }

    return (
        <div className='insurancePage'>
            <header className='insuranceHeader'>
                <h1>Health Insurance Plans Accepted</h1>
            </header>
            <section className='insurancePlans'>
                <p>Rise Orthopedic & Sports Physical Therapy accepts the following health insurance plans:</p>
                <ul className='insuranceList'>
                    {insurancePlans.map(plan => {
                        return (
                            <li>{plan}</li>
                        )
                    })}
                </ul>
                <p>If you don't see your insurance plan listed above, please contact our office at
                    <a href='tel: 623-234-4171'> 623-234-4171 </a>
                    so we can verify your benefits.
                </p>
            </section>
            <section id='cashRates'>
                <img src={cashRates} alt='logo'/>
                    <div className='ratesCard'>
                        <div className='PTEval'>
                            <div>
                                <h2>Physical Therapy Evaluations</h2>
                                <p>If you have an injury or you’re dealing with pain, you can always come in on your own. Some patients don’t want to wait on a referral or insurance approval and submit costs for reimbursement after paying for an evaluation.</p>
                            </div>
                            <h1>$150</h1>
                        </div>
                        <div className='followUp'>
                            <div>
                                <h2>Follow Up Appointments</h2>
                                <p>Check in with Mark and his team to analyze your progress and update us on how you’re feeling. A follow up allows us to stay on top of treatment and continue to encourage you to work towards recovery.</p>
                            </div>
                            <h1>$100</h1>
                        </div>
                        <div className='manualPT'>
                            <div>
                                <h2>Manual Physical Therapy</h2>
                                <p>Our practice offers dry needling and cupping treatment options for retail customers. Each service is billed per 15 minutes and some insurance companies allow you to submit the cost for reimbursement.</p>
                            </div>
                            <h1>$50</h1>
                        </div>
                    </div>
            </section>
            <section className='insuranceContact'>
                <div className='insuranceContactLeft'>
                    <h2>Need additional information?</h2>
                    <p>Please fill out our contact form and we'll get back to you as soon as possible.</p>
                </div>
                <div className='insuranceContactRight'>
                    <button className='requestApptBtn insuranceContactBtn' onClick={redirect}>Contact Us</button>
                </div>
            </section>
        </div>
    )
}

export default Insurance;