import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import About from './components/about';
import Contact from './components/contact';
import DryNeedling from './components/dryneedling';
import Footer from './components/footer';
import Home from './components/home';
import Insurance from './components/insurance';
import NavBar from './components/navbar';
import PatientForms from './components/patientForms';
import Patients from './components/patients';
import Services from './components/services';
import SportsMedicine from './components/sportsMedicine';

function App() {
  return (
    <div className="App">
      <NavBar />
      
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/services' component={Services}/>
        <Route path='/sports_medicine' component={SportsMedicine}/>
        <Route path='/dry_needling' component={DryNeedling}/>
        <Route path='/patients' component={Patients}/>
        <Route path='/new_patients' component={PatientForms}/>
        <Route path='/accepted_insurance_plans' component={Insurance}/>
        <Route path='/about' component={About}/>
        <Route path='/contact' component={Contact}/>
      </Switch>

      <Footer/>
    </div>
  );
}

export default App;
