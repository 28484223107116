import React from 'react';
import patientConsent from '../forms/patient-info-consent-form.jpeg';
import patientRegistration from '../forms/patient-registration-form.jpeg';
import medicalHistory1 from '../forms/patient-medical-history-form.jpeg';
import medicalHistory2 from '../forms/patient-medical-history-form-2.jpeg';
import consentPDF from '../forms/patient_consent_form.pdf';
import registrationPDF from '../forms/patient_registration.pdf';
import historyPDF1 from '../forms/medical_history_pg1.pdf';
import historyPDF2 from '../forms/medical_history_pg2.pdf';
import insurancePlans from '../data/insurancePlan';

const PatientForms = () => {
    return (
        <div className='formsPage'>
            <header className='formHeader'>
                <h1>New Patient Information</h1>
                <p>In-Network Insurance Plans & Patient Intake Forms</p>
            </header>
            <section className='insurancePlans'>
                <h1>Accepted Health Insurance Plans</h1>
                <p>Rise Orthopedic & Sports Physical Therapy accepts the following health insurance plans:</p>
                <ul className='insuranceList'>
                    {insurancePlans.map(plan => {
                        return (
                            <li>{plan}</li>
                        )
                    })}
                </ul>
            </section>
            <div className='patientForms'>
                <h2>Patient Intake Forms</h2>
                <p className='click'>Click on each PDF to print and fill out before your appointment</p>
                <section className='formsDiv'>
                    <a href={registrationPDF} target='_blank' rel='noreferrer'><img src={patientRegistration} alt='patient registration form'/></a>
                    <a href={consentPDF} target='_blank' rel='noreferrer'><img src={patientConsent} alt='patient registration form'/></a>
                    <a href={historyPDF1} target='_blank' rel='noreferrer'><img src={medicalHistory1} alt='patient registration form'/></a>
                    <a className='lastForm' href={historyPDF2} target='_blank' rel='noreferrer'><img src={medicalHistory2} alt='patient registration form'/></a>
                </section>
            </div>
        </div>
    )
}

export default PatientForms;