import React from "react";
import mark_profile from "../images/mark_profile.png";
import shea from "../images/Rise Photos 2024/Shea.jpg";
import ingrid from "../images/Rise Photos 2024/Ingrid.jpeg";
import colton from "../images/Rise Photos 2024/Colton.jpg";
import nat from "../images/Rise Photos 2024/Nat.jpg";
import britt from "../images/Rise Photos 2024/Britt.jpeg";
import brad from "../images/Rise Photos 2024/Brad.jpg";
import jade from "../images/Rise Photos 2024/Jade.jpg";
import kim from "../images/Rise Photos 2024/Kim.jpg";

const About = () => {
  const contactRise = () => {
    window.location.href = "/contact";
  };
  return (
    <div className="aboutPage">
      <header className="aboutHeader">
        <h1>Meet the Team</h1>
      </header>
      <div className="profilesDiv">
        <section className="profileSection aboutMark">
          <img src={mark_profile} alt="Mark Jagodzinski" />
          <div className="aboutText">
            <h1>Mark Jagodzinski</h1>
            <h3>Owner, PT</h3>
            <p>
              Mark is a lifelong resident of the Northwest Phoenix,
              graduating from Brophy College Prep in 1992, before finishing
              his Bachelor of Science in Exercise Physiology at NAU in
              1996. He was honored to be part of the first Doctoral class
              of Physical Therapists, completing his course of study with
              high honors in 2001. He’s spent nearly his entire career
              serving Orthopedic and Sportsmedicine patients across their
              recovery lifespan. Over the years he’s developed a passion
              for treating every patient as an individual and not just a
              diagnosis. He’s earned a reputation for paying close
              attention to the details and valuing the patient experience.
            </p>
            <p>
              Away from the office, you will find him spending time with
              his college sweetheart Mariam. Together, they’re usually
              trying to keep up with the Volleyball exploits of their
              daughters, Madelyn and Morghan. Mark also enjoys music of all
              kinds, and squeezing in a round of golf when able! He looks
              forward to the challenge of having his own practice and being
              able to strike an effective balance between serving the needs
              of his patients and growing the brand of Rise Orthopedic and
              Sports PT.
            </p>
            <div className="socialMediaLinks">
              <a
                href="https://twitter.com/JagskiDPT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/mark-jagodzinski-5b6115b8/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </section>
        <section className="profileSection aboutShea">
          <div className="aboutText aboutTextAlt">
            <h1>Shea Campbell</h1>
            <h3>PT, DPT</h3>
            <p>
              Shea is originally from Vancouver, British Columbia and grew
              up here in Arizona. He graduated with his Bachelor of Science
              degree in Exercise Science from Northern Arizona University
              in 2012. He then went on to earn his Doctorate of Physical
              Therapy in 2015 from Northern Arizona University as well.
              Shea has since continued to develop his clinical skills in
              functional movement, joint manipulation, and sports-specific
              training.
            </p>
            <p>
              His experience is comprised of orthopedics, post-surgical
              care, neuro rehabilitation, sports injury, and geriatric
              care. Shea follows ice hockey and mixed martial arts
              regularly and when away from the clinic, you will find him
              with his wife Amy and daughters Isla and Evie. Shea is a big
              proponent of the "whole body approach" and is a valuable
              addition to our staff in Surprise, Arizona.
            </p>
          </div>
          <img src={shea} alt="Shea Campbell" />
        </section>
        <section className="profileSection aboutColton">
          <img src={colton} alt="Colton Harper" />
          <div className="aboutText">
            <h1>Colton Harper</h1>
            <h3>PT, BA, TPI-2</h3>
            <p>
              Colton was born in Bend, Oregon and resided in the rainy
              Pacific Northwest of Vancouver, Washington for most of his
              childhood. He met his wife, Jennifer, at Central Washington
              University before moving down to Arizona to enjoy the
              sunshine. He received his education in Phoenix at Brookline
              College and has been treating in outpatient orthopedics since
              graduating. Colton and Jennifer have two amazing children,
              Owen and Makenna and a beautiful dog, Roxy. He loves to play
              basketball, watch movies, weightlift, sit poolside, and enjoy
              time with his family. He is FMA and TPI-2 certified and finds
              joy in helping others. His passion is to help people get back
              to doing the things they love through the power of movement.
            </p>
          </div>
        </section>
        <section className="profileSection aboutNat">
          <div className="aboutText aboutTextAlt">
            <h1>Natalie Thomas</h1>
            <h3>PTA, BS</h3>
            <p>
              Natalie Thomas is a Pennsylvania native, who graduated from
              Messiah University in 2018 with a Bachelor of Science degree
              in Applied Health Science, specializing in Physical Therapy.
              During her time at the University, Natalie excelled in
              collegiate softball, being a 2-time Conference Champion and
              NCAA DIII National Runner-Up. Natalie earned her Physical
              Therapist Assistant degree from Johnson College with high
              honors in 2022. She is passionate about functional movement
              and is dedicated to serving her Orthopedic and Sports
              Medicine patients. Away from the clinic, Natalie enjoys
              exploring the beautiful Arizona outdoors with her husband,
              Colby. She loves spending time with her family, playing
              sports, serving at her church, supporting the Pittsburgh
              Steelers, and vacationing by the ocean. She is thrilled to be
              a part of Rise Orthopedic & Sports PT and is looking forward
              to serving her new Arizona community.
            </p>
          </div>
          <img src={nat} alt="Natalie Thomas" />
        </section>
        <section className="profileSection aboutBrad">
          <img src={brad} alt="Brad" />
          <div className="aboutText">
            <h1>Bradley</h1>
            <h3>Technician</h3>
            <p>
              Bradley is a dedicated physical therapy tech, born in Las
              Vegas and raised in Surprise, Arizona. He is a proud graduate
              of Grand Canyon University with a Bachelor’s in Exercise
              Science in 2023. In his free time, Bradley enjoys quiet
              moments with close family and friends. He has a deep
              appreciation for movies, often keeping up with the latest
              films and cinematic experiences. When he isn’t at the movie
              theater, you can find Bradley playing video games, exploring
              virtual worlds, and tackling new challenges. Sports are also
              a big part of Bradley's life, particularly baseball and ice
              hockey. He enjoys watching games and cheering for his
              favorite teams, finding joy and excitement in the competitive
              spirit of sports.
            </p>
          </div>
        </section>
        <section className="profileSection aboutJade">
          <div className="aboutText aboutTextAlt">
            <h1>Jade</h1>
            <h3>Technician</h3>
            <p>
              Jade is a recent addition to the Rise Physical Therapy team.
              Born and raised in Surprise, Arizona, she is currently a
              junior at Shadow Ridge High School. With aspirations to
              advance her career in physical therapy, Jade is dedicated to
              her future endeavors. Outside of her responsibilities at
              Rise, Jade enjoys spending her free time outdoors with her
              family and friends. She is immensely grateful for the
              opportunity given to her and cherishes her role within the
              Rise family.
            </p>
          </div>
          <img src={jade} alt="Jade" />
        </section>
        <section className="profileSection aboutIngrid">
          <img src={ingrid} alt="Ingrid Miyazato" />
          <div className="aboutText">
            <h1>Ingrid Miyazato</h1>
            <h3>Practice Manager</h3>
            <p>
              Ingrid Miyazato, pictured here with Rise family members Shea
              Campbell and Brittany Moore, is our Practice Manager, our
              behind the scenes glue that holds Rise OSPT together. She
              handles our tech/EMR and billing/collections issues, having
              gained valuable executive level and customer service
              experience during her many years in the airline industry. She
              has long been a patient favorite with her trademark smile and
              reassuring compassion in her voice.
            </p>
            <p>
              When not working from home or helping out in the office, she
              can usually be found with her husband Kory supporting one of
              their five children on a soccer pitch or sport court
              somewhere! She comes from a proudly multi-National background
              and is fluent in four languages including English, Spanish,
              German, and Japanese. We are blessed to have her as part of
              our team and are grateful for her continued selfless devotion
              to helping make Rise what it is today.
            </p>
          </div>
        </section>
        <section className="profileSection aboutBritt">
          <div className="aboutText aboutTextAlt">
            <h1>Brittany</h1>
            <h3>Front Office Specialist</h3>
            <p>
              Brittany is originally from Sumter, South Carolina and grew
              up in Surprise, Arizona. She is a dedicated professional with
              a Bachelor's degree in Kinesiology from Arizona State
              University, With a background in health and wellness, she
              brings a deep understanding to her work as a front office
              specialist. Outside of her professional life, Brittany is a
              devoted wife to her high school sweetheart, Zach, and a
              loving mother to their two children, Abigail and Wyatt. They
              also share their home with a beloved black lab named Lola. In
              her free time, Brittany enjoys dancing, watching movies,
              swimming, and cherishing moments with her family. Her passion
              for health and well-being, coupled with her vibrant
              personality, make her a valued member of the Rise family.
            </p>
          </div>
          <img src={britt} alt="Brittany" />
        </section>
        <section className="profileSection aboutKim">
          <img src={kim} alt="Kim" />
          <div className="aboutText">
            <h1>Kim</h1>
            <h3>Authorization/Verification Coordinator</h3>
            <p>
              Born and raised in Chicago, Kim moved to Arizona in 2010 and
              has made the West Valley her home along with her husband,
              David, and their three adorable dogs. She is a proud fan of
              the Chicago Bears, Chicago Cubs and is also a former firearms
              competitor. Kim brings over thirty years of experience in the
              healthcare field to her position at RISE and enjoys being
              part of a team that is dedicated to the compassionate care of
              others. While off the clock, she likes to spend time with
              family and friends, work outside in the garden and is always
              ready for an afternoon of ‘retail therapy’.
            </p>
          </div>
        </section>
      </div>
      <section className="moreInfo">
        <h2>Request More Information</h2>
        <p className="readyP">
          Are you ready to rise up and feel great again?
        </p>
        <button
          className="requestApptBtn contactRiseBtn"
          onClick={contactRise}
        >
          Contact Rise
        </button>
      </section>
    </div>
  );
};

export default About;
