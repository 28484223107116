import React from "react";
import { Button } from "reactstrap";
import { Player, BigPlayButton } from "video-react";
import riseLogo from "../images/riseLogo.png";
import riseVideo from "../videos/Rise_Intro.mp4";
import rise_door from "../images/rise_new.jpeg";
import rise_interior from "../images/Rise_interior.JPG";

const Home = () => {
  const redirect = () => {
    window.location.href = "/services";
  };

  const schedule = () => {
    window.location.href = "/contact";
  };

  return (
    <div className="homePage">
      <div className="homeHero">
        <div>
          <img className="riseLogo" src={riseLogo} alt="Rise logo" />
          <p>
            We want your physical therapy experience to be more than
            routine. At Rise Orthopedic & Sports PT, we're dedicated to
            serving the greater NW Valley with individual plans that
            include honesty, integrity, proven care, and experience.
          </p>
          <button
            className="requestApptBtn scheduleBtnHome"
            onClick={schedule}
          >
            Schedule an Appointment
          </button>
        </div>
        <img className="riseDoorImg" src={rise_door} alt="Rise building" />
      </div>
      <div className="introDiv">
        <h2>We care about your rehabilitation</h2>
        <p>
          Our team cares about your progress and takes pride in building
          personal relationships that last. We understand your pain and are
          focused on a plan that works for you. Our mission is to help you
          regain the healthier and happier lifestyle you've been missing.
        </p>
        <div className="videoDiv">
          <Player className="riseIntroVideo" playsInline src={riseVideo}>
            <BigPlayButton position="center" />
          </Player>
        </div>
      </div>
      <div id="services" className="servicesDiv">
        <img className="patientImg" src={rise_interior} alt="patient" />
        <div className="servicesList">
          <h2>We're here to help</h2>
          <p>
            At Rise Orthopedic & Sports Therapy, we offer Evaluation and
            Treatment, Therapeutic and Corrective Exercise, Manual Physical
            Therapy, and Therapeutic Modalities to treat:
          </p>
          <ul>
            <li>
              All non-operative orthopedic spine and extremity conditions
            </li>
            <li>Workmen's compensation injuries</li>
            <li>Sports injuries</li>
            <li>Post operative orthopedic conditions including:</li>
            <ul>
              <li>Cervical and lumbar spine</li>
              <li>Shoulder/elbow</li>
              <li>Hip/knee/ankle/foot</li>
            </ul>
          </ul>
          <Button className="learnBtn" onClick={redirect}>
            Learn More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
