import React, { useState } from 'react';
import {
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
  } from 'reactstrap';
import logo from '../images/logo.png';
import RiseLogo from '../images/rise_red.png';
import NavHamburger from './navHamburger';

const NavBar = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropdownOpen2, setDropdownOpen2] = useState(false)

    const toggle = () => setDropdownOpen(!dropdownOpen)
    const toggle2 = () => setDropdownOpen2(!dropdownOpen2)

    const onMouseEnter = () => setDropdownOpen(true)
    const onMouseEnter2 = () => setDropdownOpen2(true)

    const onMouseLeave = () => setDropdownOpen(false)
    const onMouseLeave2 = () => setDropdownOpen2(false)

    return (
        <div className='navBar'>
            <NavbarBrand href="/"><img className='phxLogo' src={logo} alt='Rise logo'></img></NavbarBrand>
            <Nav className='desktopNav'>
                <NavItem>
                    <NavLink href="/">Home</NavLink>
                </NavItem>
                <Dropdown onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle className='dropdownMenu' href='/services'>
                        Services
                    </DropdownToggle>
                    <DropdownMenu left>
                        <DropdownItem href='/#services'>
                            Services Offered
                        </DropdownItem>
                        <DropdownItem href='/sports_medicine'>
                            Sports Medicine
                        </DropdownItem>
                        <DropdownItem href='/dry_needling'>
                            Dry Needling
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown onMouseOver={onMouseEnter2} onMouseLeave={onMouseLeave2} isOpen={dropdownOpen2} toggle={toggle2}>
                    <DropdownToggle className='dropdownMenu' href='/patients'>
                        Patients
                    </DropdownToggle>
                        <DropdownMenu left>
                            <DropdownItem href='/new_patients'>
                                New Patient Information
                            </DropdownItem>
                            <DropdownItem href='/accepted_insurance_plans'>
                                Insurances Accepted
                            </DropdownItem>
                            <DropdownItem href='/accepted_insurance_plans#cashRates'>
                                Cash Rates
                            </DropdownItem>
                        </DropdownMenu>
                </Dropdown>
                <NavItem className='aboutLink'>
                    <NavLink href="/about">About</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/contact">Contact</NavLink>
                </NavItem>
            </Nav> 
            <Nav className='navContact'>
                <NavItem>
                    <NavLink className='address' href='https://goo.gl/maps/6jUHpdrgipAmEeG47' target='_blank' rel='noreferrer'><i className="fas fa-map-marker-alt"></i> 12515 W Bell Rd Suite 103, Surprise, AZ 85378</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='tel: 623-234-4171'><i className="fas fa-phone-alt"></i> 623.234.4171</NavLink>
                </NavItem>
            </Nav>
            <a className='riseNavLogo_a' href='/'><img className='riseNavLogo1' src={RiseLogo} alt='Rise logo'/></a>
            <NavHamburger/> 
        </div>
    )
}

export default NavBar;