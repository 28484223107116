import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import riseLogo from "../images/riseLogo.png";

const Footer = () => {
  return (
    <footer>
      <div className="footerTop">
        <div className="footerLogo">
          <a href="/">
            <img src={riseLogo} alt="Rise logo"></img>
          </a>
        </div>
        <div className="footerContact">
          <h3>Contact</h3>
          <a href="mailto:mark.jagodzinski@riseorthosportspt.com">
            <p className="markEmail">
              mark.jagodzinski@riseorthosportspt.com
            </p>
          </a>
          <div>
            <a href="tel: 623-234-4171">
              <p>P: 623.234.4171</p>
            </a>
            <p className="faxNumber">| F: 623.234.4173</p>
          </div>
          <a
            href="https://goo.gl/maps/6jUHpdrgipAmEeG47"
            className="addressLink"
          >
            <p className="addressLink">12515 W Bell Rd, Suite 103</p>
            <p className="addressLink">Surprise, AZ 85378</p>
          </a>
        </div>
        <div className="footerSocial">
          <h3>Connect on Social Media</h3>
          <a
            href="https://www.yelp.com/biz/rise-orthopedic-and-sports-physical-therapy-surprise"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-yelp"></i>Yelp
          </a>
          <a
            href="https://www.instagram.com/rise_ortho_sports_pt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>Instagram
          </a>
          <a
            href="https://twitter.com/Rise_Ortho_PT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>Twitter
          </a>
          <a
            href="https://www.facebook.com/Rise.Ortho.PT.Surprise.AZ/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>Facebook
          </a>
        </div>
      </div>
      <Nav className="footerNav">
        <NavItem>
          <NavLink href="/">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/services">Services</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/patients">Patients</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/about">About</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/contact">Contact</NavLink>
        </NavItem>
      </Nav>
      <p className="copyright">
        &copy; Rise Orthopedic & Sports Physical Therapy
      </p>
    </footer>
  );
};

export default Footer;
