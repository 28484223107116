import React from 'react';
import logo from '../images/riseLogo.png';
import dryNeedling2 from '../images/dryNeedling2.jpg';
import dryNeedling3 from '../images/dryNeedling3.jpg';
import dryNeedling4 from '../images/dryNeedling4.jpg';
import intake from '../forms/dry_needling_intake.jpg';
import intakePDF from '../forms/dry_needling_intake.pdf';
import registration from '../forms/patient-registration-form.jpeg';
import registrationPDF from '../forms/patient_registration.pdf';

const DryNeedling = () => {
    const contactRise = () => {
        window.location.href = '/contact'
    }
    return (
        <div className='dryNeedlingPage'>
            <header className='dryNeedlingHeader'>
                <img src={logo} alt='logo'/>
                <h1>Dry Needling Therapy at Rise Orthopedic & Sports PT</h1>
                <p>
                    Pain can be triggered by soft tissue in a band of skeletal muscles and sent to other 
                    parts of your body. Dry needling focuses on relieving this pain and restoring 
                    mobility so other aspects of physical therapy aren't limited.
                </p>
            </header>
            <section className='howItHelps'>
                <h2>How Dry Needling Therapy Helps</h2>
                <div className='howItHelpsText'>
                    <p>
                    Dry needling can help loosen stiff muscles, ease joint pain, and improve 
                    blood flow and oxygen circulation within the body. Needles are inserted into the skin, 
                    targeting trigger points in your muscles or tissue. By focusing on these trigger points, 
                    we're able to help relieive pain and improve mobility.
                    </p>
                    <button className='requestApptBtn dryNeedleBtn' onClick={contactRise}>Schedule an Appointment</button>
                </div>
                <div className='howItHelpsImgs'>
                    <img src ={dryNeedling3} alt='dry needling'/>
                    <img src ={dryNeedling4} alt='dry needling'/>
                    <img src={dryNeedling2} alt='dry needling'/>
                </div>
            </section>
            <section className='dryNeedlingIntake'>
                <div>
                    <h2>Dry Needling Intake Form</h2>
                    <p>
                        If you've never received dry needling therapy from us at Rise, 
                        we ask that you fill out a consent form prior to receiving treatment. 
                        If you'd like to fill out the form prior to your appointment, simply 
                        click on the image of the form and print the attached PDF.
                    </p>
                </div>
                <div>
                    <a href={intakePDF} target='_blank' rel='noreferrer'><img src={intake} alt='dry needling intake form'/></a>
                    <a href={registrationPDF} target='_blank' rel='noreferrer'><img src={registration} alt='patient registration form'/></a>
                </div>

            </section>
        </div>
    )
}

export default DryNeedling;